<template>
  <v-container>
    <v-card v-if="forms.length <= 0" fluid class="whiteBackGround">
      <first-page
        :img-src="imgSrc"
        :dialog-name.sync="noDialog"
        :points="points"
        :headline="headline"
        :btn-url="btnUrl"
      ></first-page>
    </v-card>

    <!-- full page background color -->
    <v-card
      fluid
      flat
      tile
      height="92vh"
      class="back mb-n15 pa-6 px-md-15"
      v-if="forms.length > 0"
    >
      <!-- to adjust in different media screen -->
      <v-row v-if="loader">
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="n in 5" :key="n">
          <v-skeleton-loader type="image, actions" />
        </v-col>
      </v-row>
      <v-row v-else>
        <!-- the add form card-->
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <!-- align everything to the center and justify center -->
          <v-card
            height="100%"
            flat
            class="cursor-pointer rounded d-flex align-center justify-center text-center"
            @click="$router.push({ name: 'New Form' })"
          >
            <!-- plus and the text are in different line so another div -->
            <div class="text-center">
              <!-- element plus -->
              <div class="d-flex justify-center">
                <v-icon size="100">
                  mdi-plus
                </v-icon>
              </div>
              Create a New Form
            </div>
          </v-card>
        </v-col>
        <!-- the forms already-->
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          v-for="(form, i) in forms"
          :key="i"
        >
          <!-- align everything to the center and justify center -->
          <v-card
            height="100%"
            flat
            class="cursor-pointer rounded thumb white--text mb-5"
          >
            <!-- cross btn to delete the form -->
            <v-card-title class="pa-0 ma-0">
              <v-spacer></v-spacer>
              <v-btn icon dark @click="removeForm(form, i)"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-title>
            <!-- form name and desc -->
            <v-card-text class="text-center ">
              <p
                class="text-uppercase text-h5 font-weight-bold mb-1 white--text"
              >
                {{ form.name }}
              </p>
              <p class="text-body-2 grey--text text--lighten-4 text-truncate">
                {{ form.desc }}
              </p>
            </v-card-text>
            <!-- edit button -->
            <v-card-actions class="d-flex justify-center">
              <!-- when click this will redirect to the edit form with the id of the form -->
              <v-btn
                fab
                @click="
                  $router.push({ name: 'Edit Form', params: { id: form.id } })
                "
              >
                <v-icon color="#9cc134">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                fab
                @click="
                  $router.push({
                    name: 'Edit Form',
                    params: { id: form.id, tab: 3 },
                  })
                "
              >
                <v-icon color="#9cc134">mdi-eye</v-icon>
              </v-btn>
              <v-btn fab @click="removeForm(form, i)">
                <v-icon color="#9cc134">mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import firstPage from "@/components/firstPage.vue";
export default {
  components: {
    firstPage,
  },
  data() {
    return {
      noDialog: false,
      btnUrl: "/newform",
      headline: "Supercharge Your Data Collection",
      points: [
        "Build forms in minutes: Multiple choice, short answer, checkboxes - design it all with ease.",
        "Empower telecallers: Focus on conversations, not note-taking. Collect data seamlessly during calls.",
        "Actionable insights: Download reports, analyze trends, make data-driven decisions. ",
      ],
      forms: [], // collection of forms will be stored here
      loader: true, // to wait till the data is ready
    };
  },
  computed: {
    imgSrc() {
      return require("../assets/gotask.svg");
    },
  },
  created() {
    // getting all the forms in this account at the time of creation of the page
    this.$http
      .get(
        `${this.$store.state.ENDPOINT}/forms?filter[where][companyId]=${this.$store.state.companyDetails.id}`
      )
      .then((res) => {
        this.forms = res.data;
        this.loader = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    // removing the form
    async removeForm(form, index) {
      var flag = 0; // to store if the user confirms to delete the form
      // await as the next step depends to the user's choice
      await this.$swal({
        type: "warning",
        text: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonColor: "#31a848",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((yes) => {
        // user confirmation
        if (yes.value) {
          flag = 1;
        }
      });

      if (flag == 1) {
        // removing the forms from database
        this.$http
          .delete(`${this.$store.state.ENDPOINT}/forms/${form.id}`)
          .then((res) => {
            this.$swal({
              type: "success",
              text: "Form is successfully deleted",
            });
          })
          .catch((err) => {
            console.log(err);
          });
        // removing the form from the array so that no refresh is needed
        this.forms.splice(index, 1);
      }
    },
  },
};
</script>
<style>
.thumb:hover {
  background: #6d8823 !important;
}
</style>
